import React from 'react';
import styled from 'styled-components';

export default function MgrConsole(props) {
  return (
    <Wrapper>
      <img src="/images/bakey.png" alt="bakey" />
      <h2>請由左側選單選擇功能</h2>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 85vh;

  & img {
    width: 300px;
    height: 300px;
    object-fit: contain;
  }
`;
